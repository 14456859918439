<template>

    <!-- Steps left -->
    <!-- <div class="hidden fixed left-20 md:flex flex-col mb-3 gap-2  "
        style="z-index:99; top:50%; transform:translateY(-50%)">
        <Button @click="active = 0" rounded label="1" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 0" />
        <Button @click="active = 1" rounded label="2" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 1" />
        <Button @click="active = 2" rounded label="3" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 2" />
        <Button @click="active = 3" rounded label="4" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 3" />
        <Button @click="active = 4" rounded label="5" class="w-[3rem] text-xl h-[3rem] p-0" :outlined="active !== 4" />
    </div> -->
    <!-- Ende steps -->

    <!-- Caculator -->
    <div class="min-h-screen flex flex-col relative pt-16">

        <!-- grid -->
        <div class="px-2 md:container mx-auto grid md:grid-cols-2 gap-8">

            <!-- Left -->
            <div class="flex flex-col gap-6">


                <!-- Unterscheidung Gebäude - initial ausgeblendet wegen zeit  -->

                <div class="flex flex-col w-full hidden">
                    <span class=" mb-4 "> Bei dem Gebäude handelt es sich um ein</span>
                    <div class="flex gap-4">
                        <div>
                            <Radiobutton v-model="formData.gebaeudeart" name="gebaeudeart" value='wg' />
                            <label for="true" class="ml-2 cursor-pointer">Wohngebäude</label>
                        </div>
                        <div>
                            <Radiobutton v-model="formData.gebaeudeart" name="gebaeudeart" value='nwg' />
                            <label for="false" class="ml-2 cursor-pointer">Nichtwohngebäude</label>
                        </div>
                    </div>
                </div>

                <!-- Upload -->
                <div class="flex flex-col mb-4">
                    <FileUpload chooseLabel="Bid hochladen" name="fileupload" customUpload
                        @uploader="customUpload($event)" @upload="onAdvancedUpload($event)" :multiple="false"
                        v-model="formData.file" accept="image/*" :maxFileSize="1000000">
                        <template #empty>
                            <p>Drag and drop files to here to upload.</p>
                        </template>
                    </FileUpload>
                </div>

                <!-- Note to myself: Nur WOHNGEBÄUDE (vorerst) -->

                <h3>Angaben zum Gebäude </h3>

                <!-- -Baujahr todo: between -->



                <div class="flex flex-wrap w-full gap-x-2 gap-y-6">

                    <div class="flex flex-wrap gap-2 w-full">
                        <FloatLabel class="w-1/2">
                            <InputText class="w-full" v-model="formData.street" />
                            <label for="street ">Straße</label>
                        </FloatLabel>

                        <FloatLabel class="">
                            <InputText v-model="formData.number" />
                            <label for="no">Nr</label>
                        </FloatLabel>
                    </div>
                </div>

                <div class="flex flex-wrap w-full gap-x-2 gap-y-6">

                    <div class="flex flex-wrap gap-2 w-full">
                        <FloatLabel class="">
                            <InputText v-model="formData.zip" />
                            <label for="no">plz</label>
                        </FloatLabel>

                        <FloatLabel class="w-1/2">
                            <InputText class="w-full" v-model="formData.place" />
                            <label for="street">Ort</label>
                        </FloatLabel>
                    </div>
                </div>

                <div class="w-full flex jusfity-center items-center mb-6">
                    <Checkbox v-model="formData.hideAdress" binary variant="filled" :value=true />
                    <label for="hideadress" class="ml-2 cursor-pointer">Gebäudeadresse auf PDF ausblenden</label>
                </div>

                <div class="flex flex-wrap w-full gap-x-2 gap-y-6">

                    <div class="flex flex-col">
                        <FloatLabel>
                            <Calendar :maxDate=maxYear v-model="formData.baujahr" view="year" dateFormat="yy" />
                            <label for="baujahr">Gebäudebaujar</label>
                        </FloatLabel>
                        <div><small >Der Bauantrag muss mindestens 5 Jahre zurück liegen</small></div>
                    </div>

                    <FloatLabel>
                        <InputNumber class="w-1/4" v-model="formData.wohneinheiten" />
                        <label for="wohneinheiten">Wohneinheiten</label>
                    </FloatLabel>
                </div>

                <div class="flex flex-wrap gap-2 w-full">

                    <div class="flex flex-col w-full">
                        <span class=" mb-4"> Das Gebäude ist denkmalgeschützt oder es handelt sich um
                            erhaltenswerte Bausubstanz*</span>
                        <div class="flex gap-4">
                            <div>
                                <Radiobutton v-model="formData.denkmalschutz" name="denkmalschutz" :value=true />
                                <label for="true" class="ml-2 cursor-pointer">Ja</label>
                            </div>
                            <div>
                                <Radiobutton v-model="formData.denkmalschutz" name="denkmalschutz" :value=false />
                                <label for="false" class="ml-2 cursor-pointer">Nein</label>
                            </div>
                        </div>
                    </div>

                </div>




                <!-- Heizung -->
                <h3>Angaben zur Heizung </h3>


                <div class="flex gap-4">

                    <div class="flex flex-wrap gap-6 w-full">

                        <FloatLabel>
                            <Calendar v-model="formData.heizungBaujahr" view="year" dateFormat="yy" />
                            <label for="baujahr-heizung">Baujahr der Heizung</label>
                        </FloatLabel>

                        <!-- Heizungsart - ausgebledet, weil nicht in den Vorgaben!   -->
                        <!-- <FloatLabel>
                        <Dropdown :options=heizungsart option-label='label' placeholder='Bitte wählen' v-model="formData.heizung" />
                        <label for="heizung">Heizungsart
                        </label>
                    </FloatLabel> -->

                        <FloatLabel>
                            <Dropdown :options=energietraeger option-label="name" placeholder='Bitte wählen'
                                v-model="formData.energietraeger" />
                            <label for="energieträger">Energieträger</label>
                        </FloatLabel>

                    </div>
                </div>

                <div class="flex w-full justify-end">
                    <Button @click="foerdercheck()">Fördercheck starten</Button>
                </div>

            </div>




            <!-- Rechts -->
            <div class="flex flex-col gap-6">

                <!-- Bild -->
                <div class="h-80 flex justify-center w-full bg-gray-200 text-black">
                    <img class="object-scale-down" :src=formData.file alt="Uploaded image">
                </div>
                <!-- <div>
                    Baujahr: {{ new Date(formData.baujahr).getFullYear() }} <br>
                    Wohneinheiten: {{ formData.wohneinheiten }} <br>
                    denkmalschutz: ·{{ formData.denkmalschutz }} <br>
                    heizung baujahr: {{ new Date(formData.heizungBaujahr).getFullYear() }}, {{ formData.heizung.label
                    }},
                    {{ formData.energietraeger.name }}
                </div> -->

                <div v-if="loading" class="progress-bar">
                    <div class="progress" :style="{ width: progress + '%' }"></div>
                </div>

                <a v-if="showResult" class="text-sm w-fit xl:text-xl flex bg-primary-500 text-white rounded-3xl px-6 py-3 transition hover:bg-black" :href=pdfUrl target="_blank">DOWNLOAD</a>

            </div>

        </div>


    </div>


    <!-- ZWischenspeicher SVG -->
    <!-- <svg width="44" height="22" viewBox="0 0 44 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M28 0H0V22H28L44 11L28 0Z" fill="#BA4532" />
        <path d="M11.6713 6.032V10.246H7.30326V6.032H5.59526V16H7.30326V11.688H11.6713V16H13.4073V6.032H11.6713Z"
            fill="white" />
    </svg> -->


</template>

<!-- primevue org v3 -->

<script setup lang='js'>
import { onMounted, ref } from "vue";
import { FormService } from "../services/FormService.js"

import Dropdown from "primevue/dropdown";
import Checkbox from "primevue/checkbox";
import Radiobutton from "primevue/radiobutton";
import HeadingText from "./ui/HeadingText.vue";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import FloatLabel from 'primevue/floatlabel';
import InputMask from 'primevue/inputmask';
import Textarea from 'primevue/textarea';
import Calendar from 'primevue/calendar';

import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from "primevue/button";
import Dialog from 'primevue/dialog';

import FileUpload from 'primevue/fileupload';

const formData = ref({
    gebaeudeart: 'wg',
    file: "",
    street: "",
    number: "",
    zip: "",
    place: "",
    hideAdress: false,
    baujahr: new Date(),
    denkmalschutz: false,
    wohneinheiten: 1,
    heizungBaujahr: new Date(),
    heizung: "",
    energietraeger: "",
    result: {
        effizienzhausKlasse: '',
        finalerEffizienzhausZuschuss: 0,
        foerderungSonstige: 0,
        foerderungHeizung: 0,
        forderungGesamt: 0,
        maxErgebnis: 0
    }
});

const heizungsart = [
    { label: 'Zentral', value: 'zentral' },
    { label: 'Dezentral', value: 'dezentral' },
]

const energietraeger = [
    {
        name: "Gas",
        code: "gas",
    },
    {
        name: "Heizöl",
        code: "heizoel",
    },
    {
        name: "Holzkohle",
        code: "holzkohle",
    },
    {
        name: "Stückholz",
        code: "steuckholz",
    },
    {
        name: "Pellet",
        code: "pellet",
    },
    {
        name: "Strom",
        code: "strom",
    },
    {
        name: "Regenerativ",
        code: "regenerativ",
    },
]

const maxYear = getDateMinusYears(5);
const hideAdress = ref(false);


function getDateMinusYears(years) {
    const date = new Date(); // aktuelles Datum
    date.setFullYear(date.getFullYear() - years); // 5 Jahre abziehen
    return date; // das Datum zurückgeben
}

const onAdvancedUpload = (event) => {
    toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
};

const customUpload = (event) => {
    const file = event.files[0]; // Die erste hochgeladene Datei
    console.log(file)

    if (file) {
        customBase64Uploader(event)
    }
}

const maxSummeProWohneinheit = 60000;
const maxSummeHeizungProWohneinheit = 30000;

const maxFoerdersumme = ref(0);
const maxFoerdersummeHeizung = ref(0);

const foerderSatzSonstige = 0.2;
const basisFoerdersatzHeizung = 0.3;
const effizienzBonusSatz = 0.05;

// Werden kummuliert wenn Foerderung sonst 70 % überschreitet
const klimaBonusSatz = 0.2;
const einkommensBonusSatz = 0.2;



function calculateFoerderungSonstige(wohneinheiten) {
    // Setze den maximalen Förderbetrag basierend auf der Anzahl der Wohneinheiten
    let foerderungSonstige = (wohneinheiten * maxSummeProWohneinheit) * foerderSatzSonstige;
    return foerderungSonstige;
}

function calculateFoerderungHeizung(wohneinheiten, heizungBaujahr) {

    const maxHeizungBaujahr = getDateMinusYears(20);
    console.log('MAX BAUJAHR ' + maxHeizungBaujahr.getFullYear())

    formData.value.heizungBaujahr = formData.value.heizungBaujahr.getFullYear()



    console.log('BAUJHAR HEIZUNG ' + formData.value.heizungBaujahr + ' ')


    // wird immer gerechnet
    let basisFoerderungHeizung = (wohneinheiten * maxSummeHeizungProWohneinheit) * basisFoerdersatzHeizung;
    let einkommensBonus = maxSummeHeizungProWohneinheit * einkommensBonusSatz;
    let effizienzBonus = (wohneinheiten * maxSummeHeizungProWohneinheit) * effizienzBonusSatz;

    // klimabonus nur bei austausch öl,gas älter 20 jahre
    // todo: Jahr prüfen
    let klimaBonus = 0;
    klimaBonus = (wohneinheiten * maxSummeHeizungProWohneinheit) * klimaBonusSatz;

    let foerderungHeizung = basisFoerderungHeizung + einkommensBonus + effizienzBonus + klimaBonus;
    return foerderungHeizung;
}


// Förderfähige Kosten und Tilgungszuschuss

let proWohneinheitEffizienzhausWg = 150000;
let zuschussEffizienzhausWg = 0.25;

let proWohneinheitEffizienzhausWgDenkmal = 150000;
let zuschussEffizienzhausWgDenkmal = 0.1;


let proQmEffizienzgebNwg = 2000;
let zuschussEffizienzhausNwg = 0.25;

let proQmEffizienzgebNwgDenkmal = 2000;
let zuschussEffizienzhausNwgDenkmal = 0.1;

// Maximale Förderfähige Kosten
let maxEffizienzgebNwg = 10000000;

const finalerEffizienzhausZuschuss = ref(0);
let kosten = 0;

function getEffizienzhausFoerderung(gebaeudeart = 'wg', denkmalschutz, einheiten) {

    if (gebaeudeart == 'wg') {
        if (denkmalschutz) {
            formData.value.result.finalerEffizienzhausZuschuss = (einheiten * proWohneinheitEffizienzhausWgDenkmal) * zuschussEffizienzhausWgDenkmal
            formData.value.result.effizienzhausKlasse = 'Effizienzhaus Wohngebäude (Denkmal) - Denkmal EE'
        } else {
            formData.value.result.finalerEffizienzhausZuschuss = (einheiten * proWohneinheitEffizienzhausWg) * zuschussEffizienzhausWg
            formData.value.result.effizienzhausKlasse = 'Effizienzhaus Wohngebäude - 70EE - WPB'
        }
    }

    if (gebaeudeart == 'nwg') {
        if (denkmalschutz) {
            kosten = einheiten * proQmEffizienzgebNwgDenkmal;
            formData.value.result.finalerEffizienzhausZuschuss = kosten < maxEffizienzgebNwg ? kosten * zuschussEffizienzhausNwgDenkmal : maxEffizienzgebNwg * zuschussEffizienzhausNwgDenkmal;
            formData.value.result.effizienzhausKlasse = 'Effizienzhaus Nichtwohngebäude (Denkmal) - Denkmal EE'
        } else {
            kosten = einheiten * proQmEffizienzgebNwg;
            formData.value.result.finalerEffizienzhausZuschuss = kosten < maxEffizienzgebNwg ? kosten * zuschussEffizienzhausNwg : maxEffizienzgebNwg * zuschussEffizienzhausNwg;
            formData.value.result.effizienzhausKlasse = 'Effizienzhaus Nichtwohngebäude - 70EE - WPB'
        }
    }

}

function changeEffizienzhaus() {
    console.log('change Eff')
    if (formData.value.denkmalschutz == true) {
        formData.value.effizienzhausKlasse = "KfW Effizenzhaus Denkmal"
    } else {
        formData.value.effizienzhausKlasse = "KfW Effizienzhaus 55"
    }
}

const tempFile = ref();
const customBase64Uploader = async (event) => {
    const file = event.files[0];
    tempFile.value = file.objectURL;

    console.log(file)

    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url

    reader.readAsDataURL(blob);

    reader.onloadend = function () {
        const base64data = reader.result;
        formData.value.file = base64data;
    };

    // formData.file.value = base64data;
};

function foerdercheck() {
    startCalculation();
    console.log(formData)

    formData.value.result.foerderungSonstige = calculateFoerderungSonstige(formData.value.wohneinheiten)
    formData.value.result.foerderungHeizung = calculateFoerderungHeizung(formData.value.wohneinheiten, 2020)
    formData.value.result.forderungGesamt = formData.value.result.foerderungSonstige + formData.value.result.foerderungHeizung;

    formData.value.baujahr = formData.value.baujahr.getFullYear() 
    getEffizienzhausFoerderung('wg', formData.value.denkmalschutz, formData.value.wohneinheiten);

    formData.value.result.maxErgebnis = formData.value.result.finalerEffizienzhausZuschuss > formData.value.result.forderungGesamt ? formData.value.result.finalerEffizienzhausZuschuss : formData.value.result.forderungGesamt;




    sendFormData(formData)
}


const pdfUrl = ref('');
const showResult = ref(false)

async function sendFormData(res) {

    setTimeout(() => {

        try {
            const xhr = new XMLHttpRequest();
            xhr.open("POST", "/producer", true);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhr.setRequestHeader("X-October-Request-Flash", "1");
            xhr.setRequestHeader("X-October-Request-Handler", "producer::onGetPdf");
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {

                        console.error("DATA:", xhr.responseText);

                        const data = JSON.parse(xhr.responseText);
                        //  console.log(data)

                         pdfUrl.value = data.result;
                         showResult.value=true;
                        // console.log(this.pdfUrl)

                        // handleResponseData(data);
                    } else {
                        console.error("Error:", xhr.status);
                    }
                }
            };

            const requestBody = JSON.stringify(res);
            xhr.send(requestBody);

        } catch (error) {
            console.error("Error:", error);
        }
    }, "300");

}

const loading = ref(false);

function startCalculation() {
    loading.value = true;
    let progress = 0;

    const interval = setInterval(() => {
        if (progress < 100) {
            progress += 1; // Erhöhen Sie den Fortschritt
        } else {
            clearInterval(interval); // Stoppen Sie den Interval, wenn der Fortschritt 100 erreicht
            loading.value = false; // Setzen Sie das Laden zurück
        }
    }, 500); // Erhöhung alle 500ms
}


</script>
<style>
.progress-bar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress {
    height: 20px;
    background-color: #4caf50;
    text-align: center;
    color: white;
}
</style>