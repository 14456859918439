import {createApp} from "vue"
import PrimeVue from "primevue/config";
import Aura from "./aura-prime-oc"
import Tooltip from "primevue/tooltip";

import TestVue from "./components/TestVue.vue";
import CheckIn from "./components/CheckIn.vue";
import IconAblauf from "./components/IconAblauf.vue";
import isfp from "./components/isfp.vue";
import Foerdercheck from "./components/Foerdercheck.vue";




const app = createApp({})

//import all vue components from VueComponents folder
// Object.entries(import.meta.glob('./VueComponents/**/*.vue', { eager: true })).forEach(([path, definition]) => {
//     app.component(path.split('/').pop().replace(/\.\w+$/, ''), definition.default);
// });

// import PrimeVueTest from "./VueComponents/primevuetest.vue";
// import EBForm from "./VueComponents/EBForm.vue";

// app.component("prime-vue-test", PrimeVueTest)
// app.component("eb-form", EBForm)

app.component("TestVue", TestVue);
app.component("CheckIn", CheckIn);
app.component("IconAblauf", IconAblauf);
app.component("isfp", isfp);
app.component("Foerdercheck", Foerdercheck);




app.use(PrimeVue, {
    unstyled: true,
    ripple  : false,
    pt      : Aura,
})

app.directive("tooltip", Tooltip);
app.mount("#app-vue")
